<template>
  <div class="card card-custom card-transparent">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">1</div>
                <div class="wizard-label">
                  <div class="wizard-title">User Information</div>
                  <div class="wizard-desc">Name, Surname, Email, Mob ...</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">2</div>
                <div class="wizard-label">
                  <div class="wizard-title">Role</div>
                  <div class="wizard-desc">Select User Role</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">3</div>
                <div class="wizard-label">
                  <div class="wizard-title">Check</div>
                  <div class="wizard-desc">View & Edit</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <a
            href="#/users/list"
            class="menu-link menu-toggle m-10 d-flex justify-content-end"
          >
            <b-icon icon="x-lg"></b-icon>
          </a>
          <div class="card-body p-0">
            <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <div class="form-group row fv-plugins-icon-container">
                      <label class="col-xl-3 col-lg-3 col-form-label"
                        >Name</label
                      >
                      <div class="col-lg-9 col-xl-9">
                        <input
                          class="form-control form-control-solid form-control-lg"
                          v-model="user.name"
                          type="text"
                        />
                      </div>
                    </div>

                    <div class="form-group row fv-plugins-icon-container">
                      <label class="col-xl-3 col-lg-3 col-form-label"
                        >Surname</label
                      >
                      <div class="col-lg-9 col-xl-9">
                        <input
                          class="form-control form-control-solid form-control-lg"
                          v-model="user.surname"
                          type="text"
                        />
                      </div>
                    </div>

                    <div class="form-group row fv-plugins-icon-container">
                      <label class="col-xl-3 col-lg-3 col-form-label"
                        >Email</label
                      >
                      <div class="col-lg-9 col-xl-9">
                        <input
                          class="form-control form-control-solid form-control-lg"
                          v-model="user.email"
                          type="email"
                        />
                      </div>
                    </div>

                    <div class="form-group row fv-plugins-icon-container">
                      <label class="col-xl-3 col-lg-3 col-form-label"
                        >Password</label
                      >
                      <div class="col-lg-9 col-xl-9">
                        <input
                          class="form-control form-control-solid form-control-lg"
                          v-model="user.plainPassword"
                          type="text"
                        />
                      </div>
                    </div>

                    <div class="form-group row fv-plugins-icon-container">
                      <label class="col-xl-3 col-lg-3 col-form-label"
                        >Phone</label
                      >
                      <div class="col-lg-9 col-xl-9">
                        <input
                          class="form-control form-control-solid form-control-lg"
                          v-model="user.phone"
                          type="number"
                        />
                      </div>
                    </div>
                    <div
                      v-if="currentUserRole === 'ROLE_SUPER_ADMIN'"
                      class="form-group"
                    >
                      <label>Kompanije</label>
                      <vSelect
                        v-model="selectedCompany"
                        :options="companies"
                        :reduce="(company) => company.id"
                        label="name"
                        class="form-control form-control-solid form-control--location-select"
                      />
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div class="form-group">
                      <label for="userRoles">Select role</label>
                      <select
                        @change="changeUserRole($event)"
                        :value="user.roles[0]"
                        class="form-control"
                        id="userRoles"
                      >
                        <option>Izaberi ovlasti korisnika</option>
                        <option value="ROLE_ADMIN">Admin</option>
                        <option value="ROLE_WORKER">Worker</option>
                        <option value="ROLE_JUNIOR">Junior</option>
                        <option value="ROLE_Watcher">Watcher</option>
                        <option value="ROLE_WATCHER_BILLS">
                          Watcher bills
                        </option>
                      </select>
                    </div>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div class="mb-10 font-weight-bold text-dark">
                      Check user input and edits
                    </div>
                    <div class="border-bottom mb-5 pb-5">
                      <div class="font-weight-bold mb-3">User Data:</div>
                      <div class="line-height-md">
                        {{ user.name }}
                        <br />
                        {{ user.surname }}
                        <br />
                        {{ user.email }}
                        <br />
                        {{ user.phone }}
                        <!--                        Opis: {{ company.description }} <br />-->
                        <!--                        OIB: {{ company.oib }} <br />-->
                        <!--                        Adresa: {{ company.address }} <br />-->
                        <!--                        Kontakt Telefon: {{ company.phone }} <br />-->
                        <!--                        Email: {{ company.email }}-->
                      </div>
                    </div>
                    <div class="mb-5 pb-5">
                      <div class="font-weight-bold mb-3">Role:</div>
                      <div class="line-height-md">
                        <span>{{ user.roles[0] }}</span>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        Previous
                      </button>
                    </div>
                    <div>
                      <button
                        v-on:click="submit"
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        Edit User
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                      >
                        Next Step
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import KTUtil from '@/assets/js/components/util'
import KTWizard from '@/assets/js/components/wizard'
import ApiService from '@/core/services/api.service'
import { BIcon } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  name: 'EditUser',
  components: {
    BIcon,
    vSelect,
  },
  data() {
    return {
      user: {
        name: null,
        surname: null,
        email: null,
        plainPassword: null,
        phone: null,
        isActive: true,
        isLoggerUse: false,
        roles: [],
        locations: [],
      },
      locations: [],
      isWatcherUser: false,
      isWatcherBills: false,
      isAllUserShown: false,
      selectedCompany: '',
      companies: [],
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Korisnici', route: '/users/list' },
      { title: 'Izmjeni Korisnika' },
    ])

    ApiService.get('locations').then((response) => {
      this.locations = [...response.data['hydra:member']]
    })

    // Initialize form wizard
    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    })

    // Validation before going to next page
    wizard.on('beforeNext', function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    })

    // Change event
    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop()
      }, 500)
    })

    if (this.$route.path.includes('all')) {
      this.isAllUserShown = true
    } else {
      this.isAllUserShown = false
    }

    this.fetchCompaniesForSelect()

    ApiService.get('users', this.$route.params.id).then((response) => {
      this.user.name = response.data.name
      this.user.surname = response.data.surname
      this.user.email = response.data.email
      this.user.plainPassword = response.data.plainPassword
      this.user.phone = response.data.phone
      this.user.isActive = response.data.isActive
      this.user.isLoggerUser = response.data.isLoggerUser
      this.user.roles = response.data.roles
      this.selectedCompany = response.data.company
        ? response.data.company.id
        : null

      this.isLoaded = true
    })
  },
  computed: {
    ...mapGetters(['currentUserRole']),
  },
  methods: {
    changeUserRole: function (event) {
      const role = event.target.value
      switch (role) {
        case 'ROLE_WORKER':
          this.user.roles = ['ROLE_WORKER', 'ROLE_USER']
          break
        case 'ROLE_ADMIN':
          this.user.roles = ['ROLE_ADMIN', 'ROLE_USER']
          break
        case 'ROLE_JUNIOR':
          this.user.roles = ['ROLE_JUNIOR', 'ROLE_USER']
          break
        case 'ROLE_WATCHER':
          this.user.roles = ['ROLE_WATCHER', 'ROLE_USER']
          this.isWatcherUser = true
          break
        case 'ROLE_WATCHER_BILLS':
          this.user.roles = ['ROLE_WATCHER_BILLS', 'ROLE_USER']
          this.isWatcherBills = true
          break
      }
    },
    submit: function (e) {
      e.preventDefault()

      if (this.selectedCompany) {
        this.user.company = `/api/v1/companies/${this.selectedCompany}`
      }

      ApiService.update('users', this.$route.params.id, this.user)
        .then(() => {
          if (this.$route.path.includes('all')) {
            this.$router.push({ name: 'all-users-list' })
          } else {
            this.$router.push({ name: 'users-list' })
          }
        })
        .catch(() => {})
    },
    fetchCompaniesForSelect() {
      ApiService.get('company/select').then(({ data }) => {
        this.companies = data
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
@import 'vue-select/src/scss/vue-select.scss';
.wizard.wizard-4 .wizard-nav .wizard-steps {
  justify-content: end;
}

.form-control {
  &--logger-select,
  &--modules-select,
  &--users-select,
  &--location-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
